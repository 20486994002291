import React from "react"

import { CustomHowWeDoIt } from "../components/how-we-do-it"
import Layout from "../components/layout"
import { PurpleHeroSection } from "../components/purple-hero-sections"
import SEO from "../components/seo"
import { TableLikeComponent } from "./product-design"
import { OtherServices } from "./product-strategy"
import { WhyChooseUs } from "./what-we-do"

const HeroSection = () => (
  <PurpleHeroSection
    titleFirst={"Custom"}
    titleSecond={"Development"}
    subtitle={
      "We build intuitive apps powered by robust backend infrastructure"
    }
    content={
      <>
        Your application is more than the code, we know that. Understanding your
        business objectives and striving to provide end-users with the best
        experience are of utmost importance to our team.
      </>
    }
    image={require("../assets/services-i-eng.svg")}
  />
)
const WADS = () => {
  const data = [
    {
      title: "Website development",
      content:
        "We keep abreast of the latest technologies to create a perfect UX and UI design as well as convenient content management components for websites that are cross- device and cross - platform compatible.",
    },
    {
      title: "Web-enabled business solutions",
      content:
        "We deliver web-based applications that help organizations to keep pace with constantly changing business needs. Such applications boast quick and easy access from anywhere on the Internet, ensure lower hardware and software costs, allow faster time to market, and provide flexible integration with other systems.",
    },
    {
      title: "Web services",
      content:
        "We utilize container technologies to build a microservices- based architecture that helps to develop powerful web applications for various platforms and devices.We also provide integration services with SOAP, REST, and JSON.",
    },
    {
      title: "Front-end App development",
      content:
        "Today, our front-end development team is the most fast-growing one in the company. We devote much time and effort to bring front-end to perfection and use the best tools and components for each of the specific areas: HTML/HTML5, Javascript and modern frameworks like Angular, React, etc.",
    },
  ]
  return (
    <CustomHowWeDoIt
      data={data}
      title="How we build solutions that impact"
      subtitle="We understand that nobody knows your business processes better than you. That is why our team will work side by side with you to offer a tailor-made web app that will help you achieve your business goals. Our team is passionate about solving complex problems quickly and effectively, helping our customers grow their businesses, and providing the highest-quality customer experience."
    />
  )
}

const MVP = () => {
  const data = [
    {
      title: "Full Ownership",
      content:
        "Touchcore customers own 100% of their software, so you’ll never pay subscription or royalty fees. Want to add another feature or more users? No problem; you have all the control.",
    },
    {
      title: "Full Flexibility",
      content:
        "With a custom-built Touchcore solution, you can do whatever you want whenever you want with your product. That means your software can grow and change with your business, not the other way around.",
    },
    {
      title: "Competitive Advantages",
      content:
        "Unlike “off-the-shelf” software, a custom-built Touchcore solution can boost your company’s performance and be a valuable IP asset, two strong competitive advantages.",
    },
    {
      title: "Technology Independent",
      content:
        "Touchcore is a technology-agnostic company. In other words, we don’t sell or promote specific technologies, so we’re 100% focused on our customers. We pick the right stack for your unique business needs and goals.",
    },
    {
      title: "User-focused development",
      content:
        "Engaging brand experiences are built on pixel-perfect design executions that focus on users. Our team of User Experience Developers — or UXDs — directly translate designs into responsive front-end interfaces that orient accessibility, localization, and performance towards audiences. DockYard is unique in deploying a team dedicated to this task.",
    },
  ]
  return (
    <section className="py-20">
      <section className="container px-5 md:px-12 pt-16 pb-48 white-dots-bg bg-cover bg-no-repeat">
        <div data-aos="fade-up" className="text-center md:w-9/12 mx-auto">
          <h4 className="text-pgray text-3xl leading-tight md:text-4xl font-semibold ">
            Go to market quickly with a high-quality Minimum Viable Product.
          </h4>
          <p className="text-pgray2 text-justify md:text-center mt-4 ">
            We use a structured process to build your MVP quickly and on budget.
            Our product experts will streamline your MVP to the most important
            features. Then, our top talent UX/UI and development teams will
            design and build your MVP from the ground up. This allows you to
            rapidly launch, prove the product’s main assumptions so your
            business can grow as soon as possible.
          </p>
        </div>
      </section>
      <section className="container px-5 md:px-12">
        <TableLikeComponent data={data} />
        <img
          data-aos="fade-left"
          alt=""
          className="mt-5"
          src={require("../assets/nesa-by-makers-uAcoCc1dKiA-unsplash.png")}
        />
      </section>
    </section>
  )
}

const CustomWebApp = () => {
  const data = [
    {
      title: "SaaS",
      content:
        "Software as a Service(SaaS) has become a prevalent delivery model for web applications.SaaS is one of the most cost- effective services as it eliminates extra expenses on hardware, installation, licensing, maintenance and support.It also offers high scalability, easy access from any device, and seamless integration with other systems.",
    },
    {
      title: "Cloud-enabled solution",
      content:
        "Cloud computing is one of the most sought after technologies with a number of impressive benefits: cost-effectiveness, flexibility, security, and access from any Internet-connected device. Cloud is a perfect solution for websites with unpredictable or variable load. CRM, ERP, HR management, automation software and e-commerce websites get the most out of moving to the cloud in terms of better performance and enhanced functionality.",
    },
    {
      title: "CMS-driven & custom websites",
      content:
        "Such websites are nicely tailored to specific demands of any business and make the company presence on the web more notable. We help businesses efficiently manage their digital content, automate critical and/or routine operations, and streamline operational efficiency. We deliver CMS-driven UI/UX and template-based websites based on WordPress, Joomla, OpenX, Sitefinity, and Drupal.",
    },
    {
      title: "E-commerce web applications",
      content:
        "Today, our front-end development team is the most fast-growing one in the company. We devote much time and effort to bring front-end to perfection and use the best tools and components for each of the specific areas: HTML/HTML5, Javascript and modern frameworks like Angular, React, etc.",
    },
  ]
  return (
    <section className="container px-5 md:px-12 py-20">
      <h5
        data-aos="fade-right"
        className="text-4xl font-black text-center text-pgray mb-8"
      >
        {" "}
        Custom web application development of:
      </h5>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
        {data.map((v, i) => (
          <div
            key={"cusweb" + i}
            data-aos="fade-left"
            data-aos-delay={200 + 100 * i}
            className="relative bg-white p-8 md:p-20 shadow-md text-pgray2"
          >
            <h6 className="text-pgray text-lg font-black mb-4">{v.title}</h6>
            <p className="pb-10">{v.content}</p>
            <button className="md:mx-20 md:my-10 md:absolute bottom-0 left-0 explore-button md:mt-8">
              Explore Now
            </button>
          </div>
        ))}
      </div>
    </section>
  )
}
const CustomDevelopment = () => (
  <Layout insightCategory="web-development">
    <SEO
      title="Custom Mobile App Development | Custom web app development services | Custom App Design | Custom app development | Touchcore"
      description="No matter how big or small your custom web or mobile app development needs are, we can build it. Get high-quality custom app design with Touchcore."
    />
    <div className="bg-plightpink">
      <HeroSection />
      <WADS />
    </div>
    <MVP />
    <CustomWebApp />
    <WhyChooseUs />
    <OtherServices />
  </Layout>
)

export default CustomDevelopment
